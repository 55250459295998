<template>
  <v-dialog
    v-model="rejectDialog"
    max-width="600px"
    @click:outside="closeRejectDialog()"
  >
    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
        Reject Refund to Finance AR
      </v-toolbar>

      <v-form lazy-validation>
        <v-container>
          <v-row>
            <v-col>
              <v-label class=""
                ><span class="text-danger">*</span>Please confirm if you wish to
                reject the refund & add a remarks:
              </v-label>
              <v-textarea
                filled
                no-resize
                name="input-7-4"
                v-model="formData.rejectReason"
                :error-messages="errorField.rejectReason"
              ></v-textarea>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="rejectApplicationToAR()"
          >
            Confirm
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="closeRejectDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Service from "@/objects/service";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";

export default {
  name: "ApApplicationSelection",
  emits: ["rejectApplicationToAR", "closeRejectDialog"],
  data: () => ({
    api: new Api(),
    service: new Service(),
    isLoading: false,
    dialog: false,
    menu: false,
    modal: false,
    menu2: false,
    hrdcData: new HrdcData(),
  }),
  computed: mapState({
    auth: (state) => state.auth.data,
    services: (state) => state.service.data.services,
  }),
  props: {
    rejectDialog: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    errorField: {
      type: Object,
      required: true,
    },
  },
  methods: {
    rejectApplicationToAR() {
      this.$emit("rejectApplicationToAR", this.formData);
    },
    closeRejectDialog() {
      this.$emit("closeRejectDialog");
    },
  },
};
</script>
